import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// images
import izi from '../../../assets/logo/izi.png'

// providers
import { useAuth } from '../../../context/auth/AuthContext';
import { ResProfileContext } from '../../../context/restaurant/ProfileContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';

import * as serverURL from '../../../config/Constants'

// MUI library
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';


export default function SignIn() {
  const { user, setUser } = useAuth();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // import profile context
  const { restaurantProfile } = useContext(ResProfileContext);

  // import validtaion context params
  const { validate, setFormErrorEmpty, formError } = useContext(ValidationContext);

  // if user is  logged in, redirect to home page
  if (user) {
    navigate('/home/' + restaurantProfile.table_qr)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrorEmpty()
    const data = new FormData(event.currentTarget);
    const body = {
      email: data.get('email'),
      password: data.get('password'),
    };
    if (validate('email', 'Email', body.email) == -1) {
      return
    }
    if (validate('input', 'Password', body.password) == -1) {
      return
    }

    setLoading(true)
    try {
      axios.post(serverURL.SERVER_URL + 'customer-login', body, {
        headers: {
          'Content-Type': 'application/json',
          'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
        }
      }).then((result) => {
        const data = result.data;
        if (result.status === 200) {
          // if user is verified
          if (data.user.email_verified_at.length > 0) {
            setUser(data.user);
            setLoading(false)
            navigate('/home/' + restaurantProfile.table_qr)
          } else {
            // if user is not verified
            // send verification email
            axios.post(serverURL.SERVER_URL + 'send-verify', { email: data.user.email }, {
              headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
              }
            }).then((result) => {
              setLoading(false)
              navigate('/email/verify', { state: data.user })
            })
          }
          // 
        }
      }).catch(function (error) {
        // setFormError(error.response.data.message.message) //Please Authenticate or whatever returned from server
        if (error.response.status == 401) {
          setError(error.response.data.message)
          setLoading(false)
        }
      })
    } catch (error) {
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={izi} alt="" />

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error.length > 1 && (
          <Typography className='text-danger fs-4'>
            {error}
          </Typography>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={formError.type == 'Email' ? true : false}
            helperText={formError.type == 'Email' ? formError.error : ''}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={formError.type == 'Password' ? true : false}
            helperText={formError.type == 'Password' ? formError.error : ''}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            startIcon={loading && <CircularProgress size={20} sx={{ color: 'grey.500' }} />}
            disabled={loading}
          >
            Sign In
          </Button>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>

          <Grid sx={{ mt: 5 }} container justifyContent="center">
            <Link to={"/signup/" + restaurantProfile.table_qr} ariant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 9, mb: 4 }}  >
        {'Copyright © '}
        <Link color="inherit" href="https://spottech.co.nz/">
          SpotTech
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Container>
  );
} 