import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// import providers
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
export default function URLChecker() {
    const navigate = useNavigate();

    // import restaurant profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    // get the route name
    const location = useLocation();
    const currentRoute = location.pathname.split('/')[1];
    // end of route name


    // return to not found if user type url manually
    useEffect(() => {
        // if (currentRoute !== 'home' && currentRoute !== 'notification') {
        //     if (restaurantProfile.length == 0) {
        //         navigate('/notFound')
        //     }
        // }
    }, [])
    // end redirect
    return (
        <div>

        </div>
    )
}
