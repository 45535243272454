import { useState } from 'react'

export const useCart = () => {
    const [cartItem, setCartItem] = useState([]);

    const addToCart = (item) => {
        let selectedSize = item.size[0];
        const existingItemIndex = cartItem.findIndex(i => i.id === item.id && i.size[0].size_id === selectedSize.size_id);
        if (existingItemIndex !== -1) {
            const existingSizeIndex = cartItem[existingItemIndex].size.findIndex(sizeItem => sizeItem.size_id === selectedSize.size_id);
            if (existingSizeIndex !== -1) {
                const updatedCart = [...cartItem];
                // updatedCart[existingItemIndex].size[existingSizeIndex].count += 1;
                updatedCart[existingItemIndex].count += 1;
                setCartItem(updatedCart);
            } else {
                // const newCartItem = {
                //     id: item.id,
                //     name: item.name,
                //     description: item.description,
                //     img: item.img,
                //     size: [{
                //         size_id: selectedSize.size_id,
                //         size: selectedSize.size,
                //         currency: selectedSize.currency,
                //         price: selectedSize.price,
                //         count: 1
                //     }],
                //     tags: item.tags,
                //     count: 1,
                // };
                // const updatedCart = [...cartItem];
                // updatedCart[existingItemIndex].size.push(newCartItem.size[0]);
                // setCartItem(updatedCart);
            }
        } else {
            const newCartItem = {
                iid: cartItem.length + 1,
                id: item.id,
                name: item.name,
                description: item.description,
                img: item.img,
                size: [{
                    size_id: selectedSize.size_id,
                    size: selectedSize.size,
                    currency: selectedSize.currency,
                    price: selectedSize.price,
                    count: 1
                }],
                tags: item.tags,
                count: 1,
            };
            setCartItem([...cartItem, newCartItem]);
        }
    }

    const removeFromCart = (item, sizeFilter = false) => {
        if (sizeFilter) {
            const itemIndex = cartItem.findIndex(i => i.id === item.id && (i.size[0].size_id === item.size[0].size_id));
            if (itemIndex !== -1) {
                if (cartItem[itemIndex].count > 1) {
                    cartItem[itemIndex].count -= 1;
                } else {
                    cartItem.splice(itemIndex, 1);
                    if (cartItem.length === 0) {
                        cartItem.splice(itemIndex, 1);
                    }
                }
            }
        } else {
            const itemIndex = cartItem.findIndex(i => i.id === item.id);
            if (itemIndex !== -1) {
                if (cartItem[itemIndex].count > 1) {
                    cartItem[itemIndex].count -= 1;
                } else {
                    cartItem.splice(itemIndex, 1);
                    if (cartItem.length === 0) {
                        cartItem.splice(itemIndex, 1);
                    }
                }
            }
        }
        setCartItem([...cartItem])
    }

    const getTotalOrdersForItem = (item, filterBy = false) => {
        if (filterBy) {
            const itemOrders = cartItem.filter(i => i.id === item.id && i.size[0].size_id === item.size[0].size_id);
            const totalOrders = itemOrders.reduce((total, currentItem) => {
                return total + currentItem.count;
            }, 0);
            return totalOrders;
        } else {
            const itemOrders = cartItem.filter(i => i.id === item);
            const totalOrders = itemOrders.reduce((total, currentItem) => {
                return total + currentItem.count;
            }, 0);
            return totalOrders;
        }
    }

    const getAllTotalOrders = () => {
        const totalOrders = cartItem.reduce((total, currentItem) => {
            return total + currentItem.count;
        }, 0);
        return totalOrders;
    }

    const getTotalPrice = () => {
        const totalPrice = cartItem.reduce((total, currentItem) => {
            return total + currentItem.size[0].price * currentItem.count;
        }, 0);
        return totalPrice;
    }

    const setCartEmpty = () => {
        setCartItem([])
    }

    return [cartItem, addToCart, removeFromCart, getTotalOrdersForItem, setCartEmpty, getAllTotalOrders, getTotalPrice]
}
