import React, { useState, useContext } from 'react';
import * as serverURL from '../../config/Constants'
import { Clock } from "react-bootstrap-icons";
import './NotificationFood.css';

// MUI library
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Food = (props) => {

    const { id, name, img, description, size, price, symbol, status, time, date, estimate_time, delivered_time, payment_status } = props.data;

    const displayStatus = (status, estimate_time) => {
        if (status == 0) {
            return <span className='badge rounded-pill bg-secondary mt-4'> Pendding </span>
        }
        else if (status == 1) {
            return (
                <>
                    <span className='badge rounded-pill bg-success mb-1 mt-2'> Accepted </span>
                    <br />
                    <span className='fs-6'>
                        <Clock />   {estimate_time}-{parseInt(estimate_time) + 5} mins
                    </span>
                </>
            )
        }
        else if (status == 2) {
            return <span className='badge rounded-pill bg-success mt-4'> Delivered </span>
        }
        else if (status == 3) {
            return <span className='badge rounded-pill bg-danger mt-4'> Dropped </span>
        }
    }

    const displaySize = (size) => {
        if (size === '0') {
            return 'N/A';
        }
        else if (size === '1') {
            return 'S'
        }
        else if (size === '2') {
            return 'M'
        }
        else
            return 'L'
    }

    return (
        <>


            <div className="col-12 food-card-notification">
                <Grid container direction="row">
                    <Grid item xs={3} className='status-box'>
                        {displayStatus(status, estimate_time)}
                    </Grid>
                    <Grid item xs={9} className='vertical-line' style={{ border: '1px solid white' }}>
                        <Grid container direction="row">
                            <Grid item container direction="row" xs={12}>
                                <Grid item xs={8} className={'fs-6 fw-bold mt-2'}>
                                    {name}
                                </Grid>
                                <Grid item xs={4} className={'mt-2 pe-2 text-end'}>
                                    <span className="badge badge-size text-dark">
                                        {displaySize(size)}
                                    </span>

                                    {payment_status == '1' && (
                                        <span className="badge bg-success">
                                            Paid
                                        </span>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" xs={12}>
                                <Grid item xs={10} className={'fs-6 text-muted'}>
                                    {description.substring(0, 50) + '..'}
                                </Grid>
                                <Grid item xs={2} className='pt-2'>
                                    <span className="fs-5 fw-bold " style={{ color: "#f47a00" }}>
                                        {symbol}{price}
                                    </span>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Food
