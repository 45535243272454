import React, { useState, useEffect, useContext } from 'react';
import './Categories.css';

import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";

import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import * as serverURL from '../../config/Constants'

const Categories = () => {

    const [categories, setCategories] = useState([]);
    const state = useLocation().state;
    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);


    useEffect(() => {
        axios.post(serverURL.SERVER_URL + 'categories-by-table-id/' + state.table_id, null, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then((result) => {
            const data = result.data;
            setCategories(data.categories);
        })

    }, [])


    return (
        <>
            <div className="col-12">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={`/home/` + restaurantProfile.table_qr}>
                                Home
                            </Link>
                            <ChevronRight className="span" /> Categories
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="p-3">
                    <b>Categories</b>
                </div>
                <div className="row category-row">
                    {
                        categories.map((category, index) => {

                            return (
                                <div className="col-3 category" key={index}>
                                    <Link to={'/foods/' + restaurantProfile.table_qr} state={{ category_id: category.id, category_name: category.name }}>

                                        <img src={serverURL.CATEGORY_IMG_URL + category.img}
                                            className="text-center rounded" alt="" />
                                        <div className="text-center fs-5">{category.name}</div>
                                    </Link>
                                </div>
                            )

                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Categories