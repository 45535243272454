import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { GeoAlt } from "react-bootstrap-icons";
import * as serverURL from '../config/Constants'
import Food from '../components/Food/Food'
import CustomCarousel from '../components/Carousel/Carousel'
import { CartContext } from '../context/cart/CartContext';
import { SearchContext } from '../context/search/SearchContext';
import { ResProfileContext } from '../context/restaurant/ProfileContext';


// mui library
import CircularProgress from '@mui/material/CircularProgress';

const Home = () => {

    const { id } = useParams();

    const [popularFoods, setPopularFoods] = useState([]);
    const [carousels, setCarousels] = useState([]);
    const [categories, setCategories] = useState([]);
    const [specialOffer, setSpecialOffer] = useState([]);
    const [profile, setProfile] = useState([]);

    // import restaurant profile context
    const { restaurantProfile } = useContext(ResProfileContext);


    useEffect(() => {
        axios.post(serverURL.SERVER_URL + 'home/' + id, null, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then((result) => {
            const data = result.data;
            setCarousels(data.carousel);
            setCategories(data.category);
            setPopularFoods(data.popular_foods);
            setProfile(data.profile)
            setSpecialOffer(data.special_offer)
        })
    }, [])


    const { cartItem, addToCart, removeFromCart, getTotalOrdersForItem } = useContext(CartContext);

    // import search function for search fragment
    const { isSearchAreaVisible, searchedFoods } = useContext(SearchContext);

    const addInCart = (item, event) => {
        event.preventDefault();
        const newItem = {
            id: item.id,
            name: item.name,
            description: item.description,
            img: item.img,
            size: [{
                size_id: item.size[0].size_id,
                size: item.size[0].size,
                currency: item.size[0].currency,
                price: item.size[0].price,
                count: 1
            }],
            tags: item.tags,
            count: 1,
        }
        addToCart(newItem);
        // console.log(newItem);
    }

    const remove = (item, event) => {
        event.preventDefault();
        removeFromCart(item);
    }

    const [calledForWaiter, setCalledForWaiter] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60);

    useEffect(() => {
        let intervalId;
        if (calledForWaiter) {
            intervalId = setInterval(() => {
                setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [calledForWaiter]);

    const callForWaiter = () => {
        if (!calledForWaiter) {
            setCalledForWaiter(true);
            axios.post(serverURL.SERVER_URL + 'call-waiter', { tbl_id: profile.table_id, customer: 'n/a' }, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                console.log(data)
            })
        }
    };

    useEffect(() => {
        if (timeLeft === 0) {
            setCalledForWaiter(false);
            setTimeLeft(60);
        }
    }, [timeLeft]);


    return (
        <>
            {isSearchAreaVisible && (
                <div style={{ marginTop: '70px', paddingRight: '10px', paddingLeft: '10px' }}>
                    {searchedFoods.length <= 0 && (
                        <CircularProgress style={{ position: 'absolute', left: '47%', top: '50%', color: '#459fca' }} />
                    )}
                    {
                        searchedFoods.map((food, index) => {
                            return (
                                <>
                                    <Link key={index} to={"/foods/details/" + restaurantProfile.table_qr} state={{ foodDetails: food }} >
                                        <Food data={food} remove={remove} addInCart={addInCart} cartItem={cartItem} getTotalOrdersForItem={getTotalOrdersForItem} />
                                    </Link>
                                </>
                            )
                        })
                    }
                </div>
            )}
            {!isSearchAreaVisible && (
                <div className="col-12 p-3 pt-3">
                    <div className="col-12 row ">
                        <div className="col-8 ">
                            <h3>Welcome to {profile.name}!</h3>
                        </div>
                        <div className="col-4 call-waiter">
                            <button className="btn bg-app-color btn-sm text-white" onClick={callForWaiter} disabled={calledForWaiter} >
                                {calledForWaiter ? `Wait ${timeLeft} sec` : 'Call Waiter'}
                            </button>
                        </div>
                        <div className="col-12">
                            <h6><GeoAlt /> Location, {profile.address?.split(',')[profile.address?.split(',').length - 1]}</h6>
                        </div>
                    </div>
                    <div className="col-12 pt-2">
                        {/* <SpecialOfferCarousel carousels={carousels} /> */}
                        <CustomCarousel carousels={carousels} specialOffer={specialOffer} />
                    </div>
                    <br />
                    <div className="col-12">
                        <div className="row">
                            <div className="h3 col-6 mb-3">Categories</div>
                            <div className="h6 col-6 text-end mt-1">
                                <Link to={'/categories/' + restaurantProfile.table_qr}
                                    state={{ table_id: profile.table_id }}
                                    className="text-info">Show all</Link>
                            </div>
                        </div>

                        <Swiper
                            spaceBetween={0}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            onSlideChange={() => console.log('slide change')}
                        >
                            {
                                categories.map((category) => {

                                    return <SwiperSlide className="swiper-slide" key={category.id}>
                                        <Link to={'/foods/' + restaurantProfile.table_qr} state={{ category_id: category.id, category_name: category.name }}>
                                            <div className="swiper-img"><img
                                                src={serverURL.CATEGORY_IMG_URL + category.img}
                                                className=" text-center" alt="" /></div>
                                            <div className="text-center fs-5">{category.name}</div>
                                        </Link>
                                    </SwiperSlide>

                                })
                            }
                        </Swiper>
                    </div>
                    <div className="col-12">
                        <div className="h3 col-12 mb-3 mt-3">Most Popular</div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            {
                                specialOffer.map((food, index) => {
                                    return (
                                        <Link key={food.id} to={"/foods/details/" + restaurantProfile.table_qr} state={{ foodDetails: food }}>
                                            <Food data={food} remove={remove} addInCart={addInCart} cartItem={cartItem} getTotalOrdersForItem={getTotalOrdersForItem} />
                                        </Link>
                                    )
                                })
                            }
                            {
                                popularFoods.map((food, index) => {
                                    return (
                                        <Link key={food.id} to={"/foods/details/" + restaurantProfile.table_qr} state={{ foodDetails: food }}>
                                            <Food data={food} remove={remove} addInCart={addInCart} cartItem={cartItem} getTotalOrdersForItem={getTotalOrdersForItem} />
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            )}
        </>
    )
}
export default Home