import React, { useContext } from 'react';
import './About.css';

import { Link } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";


import { ResProfileContext } from '../../../context/restaurant/ProfileContext';

const About = () => {

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    return (
        <>
            <div className="col-12">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={`/profile/` + restaurantProfile.table_qr}>
                                Profile
                            </Link>
                            <ChevronRight className="span" /> About
                        </div>
                    </div>
                </div>
            </div>
            <div className="container p-4">
                <div className={'h4'}>IZI-GET</div>
                <p>
                    IZI-GET get a second
                    Simplify your restaurant experience with izi-get.
                    Say goodbye to paper menus and long wait times. Streamline your restaurant's ordering process with OrderEase.
                </p>
            </div>


        </>
    )
}

export default About