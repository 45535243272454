import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ChevronRight, Trash, Cart3 } from "react-bootstrap-icons";
import { useNavigate } from 'react-router-dom';

import './Cart.css'

import * as serverURL from '../../config/Constants'
import { CartContext } from '../../context/cart/CartContext';
import Food from '../../components/cart-food/Food'
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import { NotificationContext } from '../../context/notification/NotificationContext';
import { useAuth } from '../../context/auth/AuthContext';

// MUI library
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const Cart = (props) => {
    const { user } = useAuth();
    // in here check is user is not logged in then create a userId with 90 min expiration
    let userId = '';
    // check if user is not logged in
    if (!user) {
        userId = localStorage.getItem('iziuserId');
    } else {
        userId = user.email;
    }
    // end

    const navigate = useNavigate();

    const { cartItem, addToCart, removeFromCart, getTotalOrdersForItem, setCartEmpty, getAllTotalOrders, getTotalPrice } = useContext(CartContext);

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    // import notification context
    const { setNotification } = useContext(NotificationContext);

    // class variables
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [dailogOpen, setDailogOpen] = useState(false);
    const [isPayOnline, setIsPayOnline] = useState(false);
    const [snakbarOpen, setSnakbarOpen] = useState(false);
    const [noteDailogOpen, setNoteDailogOpen] = useState(false);
    const [emailDailogOpen, setEmailDailogOpen] = useState(false);
    

    // snakbar handlers
    const handleSnackbarClose = () => {
        setSnakbarOpen(false)
    }

    // palce order dailog handlers
    const handleDailogOpen = () => {
        setDailogOpen(true);
        setIsPayOnline(false);
    };
    const handlePayDailogOpen = () => {
        setDailogOpen(true);
        setIsPayOnline(true);
    };

    const handleDailogClose = () => {
        setDailogOpen(false);
    };

    // cart handlers
    // add item to cart
    const addInCart = (item, event) => {
        event.preventDefault();
        const newItem = {
            id: item.id,
            name: item.name,
            description: item.description,
            img: item.img,
            size: [{
                size_id: item.size[0].size_id,
                size: item.size[0].size,
                currency: item.size[0].currency,
                price: item.size[0].price,
                count: 1
            }],
            tags: item.tags,
            count: 1,
        }
        addToCart(newItem);
    }
    // remove item from cart
    const remove = (item, event) => {
        event.preventDefault();
        removeFromCart(item, true);
    }
    // make cart empty
    const emptyCart = () => {
        setCartEmpty();
    }
    //end cart handlers

    // add note to orders
    const [note, setNote] = useState('');
    const addNote = () => {
        setNoteDailogOpen(false);
    }
    const handleNoteDailogClose = () => {
        setNoteDailogOpen(false);
    };
    const handleNoteDailogOpen = () => {
        setNoteDailogOpen(true);
    };
    // end add note to orders

    // ask user email when it is not logged in, to pay online
    const [userEmail, setUserEmail] = useState('');

    const handleEmailDailogOpen = () => {
        setEmailDailogOpen(true);
        // close confirm dailog
        setDailogOpen(false);
    };

    const handleEmailDailogClose = () => {
        setEmailDailogOpen(false);
    };
    // end user email

    // send order to pay in recept
    const sendOrderAndPayInRecept = async () => {
        setDailogOpen(false);
        setBackdropOpen(true)
        let orders = []
        cartItem.map((food, index) => {
            orders[index] = {
                quantity: food.count,
                tbl_id: restaurantProfile.table_id,
                menu_id: food.id,
                size_id: food.size[0].size_id,
                customer: userId,
                note: note
            }
        })

        await axios.post(serverURL.SERVER_URL + 'send-orders', { orders: orders, key: restaurantProfile.key }, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then(async (result) => {
            setBackdropOpen(false)
            setSnakbarOpen(true);
            setCartEmpty();
            setNotification(orders.length)
        })
    }

    // send orders to pay online
    const sendOrdersWithOnlinePay = (email) => (event) => {
        setDailogOpen(false);
        setEmailDailogOpen(false);
        setBackdropOpen(true)

        let orders = []
        cartItem.map((food, index) => {
            orders[index] = {
                quantity: food.count,
                tbl_id: restaurantProfile.table_id,
                menu_id: food.id,
                size_id: food.size[0].size_id,
                customer: userId,
                note: note
            }
        })
        
        axios.post(serverURL.SERVER_URL + 'send-orders', { orders: orders, key: restaurantProfile.key }, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then(async (result) => {
            const data = result.data;
            // setBackdropOpen(false)
            // setCartEmpty();
            
            axios.post(serverURL.SERVER_URL + 'checkout', { orders: data.orders, table_qr: restaurantProfile.table_qr, userId: email }, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                window.location.replace(result.data.url);
                // window.open(result.data.url, "_blank", "noreferrer");
            })
        })
        
        
    };
    return (
        <>

            {/* confirm place order dialog */}
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to place the order?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>No</Button>
                    <Button onClick={isPayOnline ? user ? sendOrdersWithOnlinePay(userId) : handleEmailDailogOpen : sendOrderAndPayInRecept} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm place order dialog */}


            {/* note dialog */}
            <Dialog open={noteDailogOpen} onClose={handleNoteDailogClose}>
                <DialogTitle>Add Note</DialogTitle>
                <DialogContent>
                    <TextField
                        id="outlined-multiline-static"
                        label="Note"
                        multiline
                        rows={4}
                        onChange={(e) => setNote(e.target.value)}
                        defaultValue={note}
                        className={`mt-1`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNoteDailogClose}>Cancel</Button>
                    <Button onClick={addNote}>Add</Button>
                </DialogActions>
            </Dialog>
            {/* End note dailog */}

            {/* email dailog */}
            <Dialog open={emailDailogOpen} onClose={handleEmailDailogClose}>
                <DialogTitle>Provide your email</DialogTitle>
                <DialogContent>
                    <TextField
                        id="outlined-multiline-static"
                        label="Email"
                        onChange={(e) => setUserEmail(e.target.value)}
                        defaultValue={userEmail}
                        className={`mt-1`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEmailDailogClose}>Cancel</Button>
                    <Button onClick={sendOrdersWithOnlinePay(userEmail)}>Continue</Button>
                </DialogActions>
            </Dialog>
            {/* End email dailog */}

            <div className="col-12">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <a href="" rel="nofollow">Home</a>
                            <ChevronRight className="span" /> Cart
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="p-1 pt-3 mb-3">
                    <b>Foods</b>
                    <button className={`float-end btn btn-sm bg-app-color white text-white`} onClick={emptyCart}><Trash /></button>
                    <button className={`float-end btn btn-sm bg-app-color white text-white me-1`} onClick={handleNoteDailogOpen} disabled={cartItem.length > 0 ? false : true}>Add note</button>

                </div>

                <div className='row'>
                    {
                        cartItem.map((food, index) => {
                            return (
                                <>
                                    <Link key={index} to={"/foods/details/" + restaurantProfile.table_qr} state={{ foodDetails: food }}>
                                        <Food data={food} remove={remove} addInCart={addInCart} cartItem={cartItem} getTotalOrdersForItem={getTotalOrdersForItem} filterBy={true} />
                                    </Link>
                                </>
                            )
                        })
                    }
                    {
                        cartItem.length <= 0 && (
                            <span style={{ position: 'absolute', top: '38%', bottom: '0', textAlign: 'center', color: '#ddd', fontSize: '3rem' }}>
                                <Cart3 style={{ color: '#ddd', fontSize: '3rem', marginBottom: '-60px' }} className={`col-12`} />
                                <span className={`h3`}>Your cart is empty!</span>
                            </span>
                        )
                    }
                </div>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
            <div className="col-12 checkout-panel end-0 start-0 px-3 py-1" >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <span className='fw-bold'>Total</span>
                    </Grid>
                    <Grid item xs={6}>
                        <span className='float-end'>${getTotalPrice()}</span>
                    </Grid>
                    {/* 
                    <Grid item xs={6}>
                        Placed Orders
                    </Grid>
                    <Grid item xs={6}>
                        <span className='float-end'>{oldPay} $</span>
                    </Grid>
                    <Grid item xs={6}>
                        <span className='fw-bold'>Grand Total</span>
                    </Grid> */}
                    {/* <Grid item xs={6}>
                        <span className='float-end fw-bold'>{parseInt(getTotalPrice()) + parseInt(oldPay)} $</span>
                    </Grid> */}
                    <Grid item xs={6}>
                        <button className={`float-end btn  bg-app-color white text-white w-100`} onClick={handleDailogOpen} disabled={cartItem.length > 0 ? false : true}>Pay at Counter</button>
                    </Grid>
                    <Grid item xs={6}>
                        <button className={`float-end btn  bg-app-color white text-white col-12`} onClick={handlePayDailogOpen} disabled={cartItem.length > 0 ? false : true}> Pay Online</button>
                    </Grid>
                </Grid>
            </div>


            <Snackbar
                open={snakbarOpen}
                autoHideDuration={1500}
                message="your orders palced, successfully"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleSnackbarClose}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Cart