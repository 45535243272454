import { createBrowserRouter } from 'react-router-dom';
import Layout from '.././components/layout/Layout'
import LayoutSecondary from '.././components/layout-secondary/Layout'
import Home from '.././pages/Home'
import Cart from '.././pages/cart/Cart'
import Notification from '.././pages/notification/Notification'
import NotFound from '.././pages/NotFound'
import TableInUsed from '.././pages/TableInUsed'

import Profile from '.././pages/profile/Profile'
import Feedback from '.././pages/profile/feedback/Feedback'
import About from '.././pages/profile/about/About'
import Contact from '.././pages/profile/contact/Contact'
import Privacy from '.././pages/profile/privacy/Privacy'
import UserProfile from '.././pages/profile/user/User'


import Categories from '.././pages/categories/Categories'
import Foods from '.././pages/food-list/FoodList'
import FoodDetails from '.././pages/food-details/FoodDetails'

// Auth pages
import SignUp from '.././pages/auth/signup/SignUp'
import SignIn from '.././pages/auth/signin/SignIn'
import Verify from '.././pages/auth/verify/Verify'

const router = createBrowserRouter([
	{
		path: '/',
		element: <LayoutSecondary />,
		children: [
			{
				path: '/signin/:id',
				element: <SignIn />,
			},
			{
				path: '/signup/:id',
				element: <SignUp />,
			},
			{
				path: '/email/verify',
				element: <Verify />,
			},
		],
	},
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/home/:id',
				element: <Home />,
			},
			{
				path: '/profile/:id',
				element: <Profile />,
			},
			{
				path: '/cart/:id',
				element: <Cart />,
			},
			{
				path: '/notification/:id',
				element: <Notification />,
			},
			{
				path: '/categories/:id',
				element: <Categories />,
			},
			{
				path: '/foods/:id',
				element: <Foods />,
			},
			{
				path: '/foods/details/:id',
				element: <FoodDetails />,
			},
			{
				path: '/profile/feedback/:id',
				element: <Feedback />,
			},
			{
				path: '/profile/about/:id',
				element: <About />,
			},
			{
				path: '/profile/contact/:id',
				element: <Contact />,
			},
			{
				path: '/profile/privacy/:id',
				element: <Privacy />,
			},
			{
				path: '/profile/user/edit/:id',
				element: <UserProfile />,
			},
		],
	},
	{
		path: '/',
		children: [
			{
				path: '/table-in-used',
				element: <TableInUsed />,
			},
			{
				path: '/*',
				element: <NotFound />,
			},
		],
	},
]);

export default router;
