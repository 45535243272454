import React, { useState, useEffect, useContext } from 'react';
import './FoodList.css';
import Food from '../../components/Food/Food'

import axios from 'axios';
import {  Link, useLocation } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import $ from 'jquery';

import * as serverURL from '../../config/Constants'
import { CartContext } from '../../context/cart/CartContext';
import { ResProfileContext } from '../../context/restaurant/ProfileContext';

const Foods = () => {

    const state = useLocation().state;
    const [categories, setCategories] = useState([]);
    const [carousels, setCarousels] = useState([]);
    const [foods, setFoods] = useState([]);
    const [foodsForFilter, setFoodsForFilter] = useState([]);
    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    useEffect(() => {
        axios.post(serverURL.SERVER_URL + 'foods/' + state.category_id, null, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then((result) => {
            const data = result.data;
            setCategories(data.categories);
            setCarousels(data.carousel);
            setFoods(data.foods);
            setFoodsForFilter(data.foods);
        })

    }, [])

    const { cartItem, addToCart, removeFromCart, getTotalOrdersForItem } = useContext(CartContext);

    const addInCart = (item, event) => {
        event.preventDefault();
        const newItem = {
            id: item.id,
            name: item.name,
            description: item.description,
            img: item.img,
            size: [{
                size_id: item.size[0].size_id,
                size: item.size[0].size,
                currency: item.size[0].currency,
                price: item.size[0].price,
                count: 1
            }],
            tags: item.tags,
            count: 1,
        }
        addToCart(newItem);
    }
    const remove = (item, event) => {
        event.preventDefault();
        removeFromCart(item);
    }

    const filterFoodList۱ = (id) => {
        setFoods([...foodsForFilter])
        const filteredList = foods.filter(food => food.category_id == id)
        setFoods(filteredList);

    }

    const filterFoodList = (id) => {
        if (id) {
            const filteredList = foodsForFilter.filter(food => food.category_id == id);
            setFoods(filteredList);
        } else {
            setFoods(foodsForFilter); // reset list to original state
        }
    }

    // jquery codes
    $(document).ready(function () {
        $('.cat-btn').click(function () {
            $('.cat-btn').removeClass('selectedButton');
            $(this).addClass('selectedButton');
        })

    })

    return (
        <>
            <div className="col-12">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={`/home/` + restaurantProfile.table_qr}>
                                Home
                            </Link>
                            <ChevronRight className="span" />
                            <Link to={'/categories/' + restaurantProfile.table_qr} state={{ table_id: restaurantProfile.table_id }}>
                                Categories
                            </Link>
                            <ChevronRight className="span" /> {state.category_name}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="col-12 pt-2">
                    {/* <CustomCarousel carousels={carousels} /> */}
                </div>
                <br />
                <div className="col-12">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={4}
                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log('slide change')}
                    >
                        {
                            categories.map((category) => {
                                return (
                                    <SwiperSlide className="swiper-slide" key={category.id}>
                                        <div className="text-center fs-6 btn btn-outline-dark w-100 text-nowrap cat-btn" onClick={() => filterFoodList(category.id)}>{category.name}</div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
                <div className="p-3">
                    <b>{state.category_name}</b>
                </div>

                <div className="row">
                    {
                        foods?.map((food, index) => {
                            return (
                                <Link key={food.id} to="/foods/details" state={{ foodDetails: food }}>
                                    <Food data={food} remove={remove} addInCart={addInCart} cartItem={cartItem} getTotalOrdersForItem={getTotalOrdersForItem} />
                                </Link>
                            )
                        })
                    }
                </div>
                <br /><br />
            </div>
        </>
    )
}
export default Foods