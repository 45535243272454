import React, { useState, useContext } from 'react';
import './FoodDetails.css';

import { useLocation, Link } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";
import $ from 'jquery';

import { FoodSize, foodSize, foodSizeId, foodPrice, foodCurrency } from './FoodSize';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import * as serverURL from '../../config/Constants'
import { CartContext } from '../../context/cart/CartContext';
import { ResProfileContext } from '../../context/restaurant/ProfileContext';

import { StarFill, Clock, Plus } from "react-bootstrap-icons";

const FoodDetails = () => {

    const state = useLocation().state;
    const foodDetails = state.foodDetails;
    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    $(document).ready(function () {
        $('.size-btn').click(function () {
            $('.size-btn').removeClass('selectedButton');
            $(this).addClass('selectedButton');
            let price = $(this).attr('price');
            $('.price-section').text(price);

        })
        $('.size-btn').each(function () {
            if ($(this).val() === foodDetails.size[0].size) {
                if (foodSizeId === undefined) {
                    $(this).addClass('selectedButton');

                }
            }
        })
    })

    const sizetest = [{
        size_id: foodDetails.size[0].size_id,
        size: foodDetails.size[0].size,
        currency: foodDetails.size[0].currency,
        price: foodDetails.size[0].price,
        count: 1
    }];



    const { cartItem, addToCart, removeFromCart, getTotalOrdersForItem } = useContext(CartContext);

    const addInCart = (item) => {
        let newItem = {}
        if (foodSizeId === undefined) {
            newItem = {
                id: item.id,
                name: item.name,
                description: item.description,
                img: item.img,
                size: sizetest,
                tags: item.tags,
                count: 1,
            }
        } else {
            newItem = {
                id: item.id,
                name: item.name,
                description: item.description,
                img: item.img,
                size: [{
                    size_id: foodSizeId,
                    size: foodSize,
                    currency: foodCurrency,
                    price: foodPrice,
                    count: 1
                }],
                tags: item.tags,
                count: 1,
            }
        }
        addToCart(newItem);
    }


    const remove = (item) => {

        let removeItem = {}
        if (foodSizeId === undefined) {
            removeItem = {
                id: item.id,
                name: item.name,
                description: item.description,
                img: item.img,
                size: sizetest,
                tags: item.tags,
                count: 1,
            }
        } else {
            removeItem = {
                id: item.id,
                name: item.name,
                description: item.description,
                img: item.img,
                size: [{
                    size_id: foodSizeId,
                    size: foodSize,
                    currency: foodCurrency,
                    price: foodPrice,
                    count: 1
                }],
                tags: item.tags,
                count: 1,
            }
        }
        removeFromCart(removeItem, true);
    }


    return (
        <>
            <div className="col-12">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={`/home/` + restaurantProfile.table_qr}>
                                Home
                            </Link>
                            <ChevronRight className="span" /> Food
                            <ChevronRight className="span" /> Details
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="card p-0">
                        <div className="card-body">
                            <div className="col-sm-12 col-lg-9 food-details">
                                <div className="food-details-img">
                                    <img className={'rounded'} src={serverURL.FOOD_IMG_URL + foodDetails.img} alt="" />
                                </div>
                                <div className="col-12 mt-3 row">
                                    <div className="col-6 fw-bold">{foodDetails.name}</div>
                                    <div className="col-6 text-end">
                                        {
                                            foodDetails.tags.map((tag, index) => {
                                                return <span key={index}
                                                    className="badge rounded-pill bg-success m-1">{tag}</span>

                                            })
                                        }
                                    </div>
                                </div>
                                <div className="col-12 mt-3 row text-black-50 " >
                                    <div className="col-2 fw-bold p-0">
                                        <div className="fd-rate">
                                            <StarFill className="rate-icon" />
                                            <span>4.5</span>
                                        </div>
                                    </div>
                                    <div className="col-5 fw-bold">
                                        <div className="fd-time">
                                            <Clock className="clock-icon" />
                                            <span>10-20 mins</span>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end size-box" >
                                        {
                                            foodDetails.size.map((size, index) => {
                                                return <FoodSize key={index} data={size} />

                                            })
                                        }
                                    </div>
                                </div>
                                <div className="col-12 mt-3 row fw-bold mt-2">
                                    <div className="col-6 ps-4 price-section" style={{ fontSize: '24px', fontWeight: '500', color: '#F47A00' }}>
                                        { foodDetails.size[0].currency + foodDetails.size[0].price}
                                    </div>
                                    <div className="col-6 ">
                                        <div className="food-card_order-count col-10 float-end">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-outline-secondary minus-btn"
                                                        type="button"
                                                        onClick={() => remove(foodDetails)}
                                                    >
                                                        &#8722;
                                                    </button>
                                                </div>
                                                <label className="form-control input-manulator text-center" style={{ backgroundColor: '#eee' }}>
                                                    {getTotalOrdersForItem(foodDetails.id)}
                                                </label>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary add-btn"
                                                        type="button"
                                                        onClick={() => addInCart(foodDetails)}
                                                    ><Plus />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="col-12 mt-1 text-black fd-description text-wrap">
                                    <div className={`fw-bold mb-1`}>Description</div>
                                    {foodDetails.description}
                                </div>
                                <br /><br />
                                <br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FoodDetails