import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

// improt izi logo
import izi from '../../../assets/logo/izi.png'

// MUI library
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// icons
import { MarkEmailRead } from '@mui/icons-material';


// providers
import { useAuth } from '../../../context/auth/AuthContext';
import { ResProfileContext } from '../../../context/restaurant/ProfileContext';

// all server APIs url
import * as serverURL from '../../../config/Constants'

export default function SignUp() {
  const { user, setUser } = useAuth();
  const [error, setError] = React.useState({});
  const state = useLocation().state;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // import profile context
  const { restaurantProfile } = useContext(ResProfileContext);

  // if user is  logged in, redirect to home page
  if (user) {
    navigate('/home/' + restaurantProfile.table_qr)
  }

  const [code, setCode] = useState(['', '', '', '', '', '']);
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const handleChange = (index, value) => {
    if (!/^\d*$/.test(value)) {
      return;
    }
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (value && index < 5) {
      refs[index + 1].current.focus();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const verificationCode = code.join('');
    if (/^\d{6}$/.test(verificationCode)) {
      setLoading(true)
      try {
        axios.post(serverURL.SERVER_URL + 'verify', {
          email: state.email,
          verification_code: verificationCode,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
          }
        }).then((result) => {
          const data = result.data;
          if (result.status === 200) {
            setUser(data.user);
            // navigate('/home/' + restaurantProfile.table_qr)
          }
        }).catch(function (error) {
          // setFormError(error.response.data.message.message) //Please Authenticate or whatever returned from server
          if (error.response.status == 401) {
            setError(error.response.data.message)
            setLoading(false)
          }
        })
      } catch (error) {
      }
    }
  }


  return (
    <>
      <div className='container'>
        <div className="col-12 text-center mt-5">
          <img src={izi} alt="" /><br />
          <span className=' fs-3'>
            Sign up
          </span>
        </div>
        <div className={'fw-bold fs-6 mt-4 textColor text-center'}>
          Verify your email
        </div>
        <div className={'mt-2 fs-6 lead text-center px-1'}>
          A verification code has been sent to your {state.email}, Please put the code here
        </div>
        <div className='col-12 mt-5 px-5'>
          <div className='row'>
            <div className='col-2 p-1'>
              <input
                className={'form-control'}
                type="text"
                maxLength="1"
                value={code[0]}
                onChange={(event) => handleChange(0, event.target.value)}
                onFocus={() => refs[0].current.select()}
                ref={refs[0]}

              />
            </div>
            <div className='col-2 p-1' >
              <input
                className={'form-control'}
                type="text"
                maxLength="1"
                value={code[1]}
                onChange={(event) => handleChange(1, event.target.value)}
                onFocus={() => refs[1].current.select()}
                ref={refs[1]}
              />
            </div>
            <div className='col-2 p-1' >
              <input
                className={'form-control'}
                type="text"
                maxLength="1"
                value={code[2]}
                onChange={(event) => handleChange(2, event.target.value)}
                onFocus={() => refs[2].current.select()}
                ref={refs[2]}
              />
            </div>
            <div className='col-2 p-1' >
              <input
                className={'form-control'}
                type="text"
                maxLength="1"
                value={code[3]}
                onChange={(event) => handleChange(3, event.target.value)}
                onFocus={() => refs[3].current.select()}
                ref={refs[3]}
              />
            </div>
            <div className='col-2 p-1' >
              <input
                className={'form-control'}
                type="text"
                maxLength="1"
                value={code[4]}
                onChange={(event) => handleChange(4, event.target.value)}
                onFocus={() => refs[4].current.select()}
                ref={refs[4]}
              />
            </div>
            <div className='col-2 p-1' >
              <input
                className={'form-control'}
                type="text"
                maxLength="1"
                value={code[5]}
                onChange={(event) => handleChange(5, event.target.value)}
                onFocus={() => refs[5].current.select()}
                ref={refs[5]}
              />
            </div>
            <div className={'col-12 text-center my-3'}>
              <MarkEmailRead style={{ fontSize: '8rem', color: '#2caeef', marginLeft: '0', marginRight: '0' }} />
            </div>
          </div>
          {error.length > 0 && (
            <div className="text-danger p-1 text-center mb-3">
              {error}
            </div>
          )}
          <button
            type="submit"
            className={'btn bg-app-color col-12 text-light'}
            disabled={code.every((value) => value !== '') ? loading ? true : false : true}
            onClick={handleSubmit}
          >
            {loading && (<CircularProgress size={20} sx={{ color: 'grey.500' }} style={{ marginRight: '5px', marginBottom: '-5px' }} />)}
            Confirm
          </button>
        </div>
      </div>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 3, mb: 1 }} >
        {'Copyright © '}
        <Link color="inherit" href="https://spottech.co.nz/">
          SpotTech
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </>
  );
}