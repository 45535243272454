import React, { useState, createContext } from 'react';

export const SearchContext = createContext(null);

export const SearchContextProvider = (props) => {

    const [isSearchAreaVisible, setIsSearchAreaVisible] = useState(false);
    const [searchedFoods, setSearchedFoods] = useState([]);

    const toggle = () => {
        setIsSearchAreaVisible((prev) => !prev)
    }

    const setSearchedFood = (foods) => {
        setSearchedFoods(foods)
    }

    const contextValue = { isSearchAreaVisible, toggle, searchedFoods, setSearchedFood};

    return <SearchContext.Provider value={contextValue}>{props.children}</SearchContext.Provider>
}

