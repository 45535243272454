import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Notification.css'

import * as serverURL from '../../config/Constants'
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import Food from '../../components/notification-food/NotificationFood'
import { NotificationContext } from '../../context/notification/NotificationContext';
import { useAuth } from '../../context/auth/AuthContext';

// import ReactPullToRefresh from 'react-pull-to-refresh';
import PullToRefresh from 'react-simple-pull-to-refresh';

// MUI library
import Grid from '@mui/material/Grid';

const Notification = ({ children }) => {

    const { user } = useAuth();

    const { id } = useParams();

    // in here check is user is not logged in then create a userId with 90 min expiration
    let userId = '';
    // check if user is not logged in
    if (!user) {
        userId = localStorage.getItem('iziuserId');
    } else {
        userId = user.email;
    }
    // end

    const [orders, setOrders] = useState([]);
    const [totalOrderPrice, setTotalOrderPrice] = useState(0);
    const [paidForOrder, setPaidForOrder] = useState(0);

    const { restaurantProfile } = useContext(ResProfileContext);
    const { setNotification } = useContext(NotificationContext);

    useEffect(() => {
        axios.post(serverURL.SERVER_URL + 'order/list/' + id, { userId: userId }, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then((result) => {
            const data = result.data;
            setOrders(data.orders);
            setNotification(0);
            let total_price = 0;
            let total_paid = 0;
            for (let i = 0; i < data.orders.length; i++) {
                total_price += parseInt(data.orders[i].price);
                if (data.orders[i].payment_status == '1') {
                    total_paid += parseInt(data.orders[i].price);
                }
            }
            setTotalOrderPrice(total_price);
            setPaidForOrder(total_paid);
        })
    }, [])

    // const handleRefresh = async () => {
    //     await axios.post(serverURL.SERVER_URL + 'order/list/' + restaurantProfile.table_id, { userId: userId }, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
    //         }
    //     }).then((result) => {
    //         const data = result.data;
    //         setOrders(data.orders);
    //         setNotification(0);
    //         console.log('page refreshed');
    //         let total_price = 0;
    //         let total_paid = 0;
    //         for (let i = 0; i < data.orders.length; i++) {
    //             total_price += parseInt(data.orders[i].price);
    //             if (data.orders[i].payment_status == '1') {
    //                 total_paid += parseInt(data.orders[i].price);
    //             }
    //         }
    //         setTotalOrderPrice(total_price);
    //         setPaidForOrder(total_paid);
    //     })
    // }


    return (
        <>
            <div className="col-12 p-3 pt-3 mb-1">
                <b>Order List</b>
            </div>

            <div style={{ margin: '0', padding: '0', height: '100%', overflow: 'scroll' }} className={`px-2`}>
                {/* <PullToRefresh onRefresh={handleRefresh} style={{ overflow: 'scroll' }} > */}
                    {
                        orders.map((order, index) => {
                            return (
                                <Food key={index} data={order} />
                            )
                        })
                    }

                    {orders.length === 0 ? (
                        // If the length is 0, execute the loop 6 times
                        Array(7).fill().map((_, index) => (
                            <div key={index} className='fake-notification-card'></div>
                        ))
                    ) : (
                        // If the length is not 0, calculate the loop iterations based on the length
                        Array(orders.length > 7 ? 0 : 7 - orders.length).fill().map((_, index) => (
                            <div key={index} className='fake-notification-card'></div>
                        ))
                    )}
                {/* </PullToRefresh > */}

            </div><br /><br /><br /><br /><br /><br />
            <div className="col-12 checkout-panel end-0 start-0 px-3 py-1" >
                <Grid container >
                    <Grid item xs={6}>
                        <span className='fw-bold'>Total</span>
                    </Grid>
                    <Grid item xs={6}>
                        <span className='float-end'>{orders != undefined && orders[0]?.symbol}{totalOrderPrice }   </span>
                    </Grid>
                    <Grid item xs={6}>
                        Paid Online
                    </Grid>
                    <Grid item xs={6}>
                        <span className='float-end'>{orders != undefined && orders[0]?.symbol}{paidForOrder} </span>
                    </Grid>
                    <Grid item xs={6}>
                        Outstanding Balance
                    </Grid>
                    <Grid item xs={6}>
                        <span className='float-end fw-bold'>{orders != undefined && orders[0]?.symbol}{totalOrderPrice - paidForOrder}</span>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Notification