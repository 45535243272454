import React, { useState, useContext } from 'react';
import './Feedback.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// components
import * as serverURL from '../../../config/Constants'
import { ChevronRight } from "react-bootstrap-icons";

// MUI library
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

// providers
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { ResProfileContext } from '../../../context/restaurant/ProfileContext';


const Feedback = () => {

    const [error, setError] = React.useState({});
    const [loading, setLoading] = useState(false);
    const [snakbarOpen, setSnakbarOpen] = useState(false);

    const navigate = useNavigate();

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormErrorEmpty()
        const data = new FormData(event.currentTarget);
        const body = {
            first_name: data.get('firstname'),
            last_name: data.get('lastname'),
            phone: data.get('phone'),
            email: data.get('email'),
            message: data.get('message'),
            rate: data.get('rate'),
        };
        if (validate('input', 'First Name', body.first_name) == -1) {
            return
        }
        if (validate('input', 'Last Name', body.last_name) == -1) {
            return
        }
        if (validate('input', 'Phone', body.phone) == -1) {
            return
        }
        if (validate('email', 'Email', body.email) == -1) {
            return
        }
        if (validate('input', 'Message', body.message) == -1) {
            return
        }

        setLoading(true)
        try {
            axios.post(serverURL.SERVER_URL + 'feedback/send', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    document.getElementById("feedback-form").reset();
                    setSnakbarOpen(true)
                    setError(null)
                }
                setLoading(false)
            }).catch(function (error) {
                setError(error.response.data.message)
                setLoading(false)
            })
        } catch (error) {
        }

    };
    // snakbar handlers
    const handleSnackbarClose = () => {
        setSnakbarOpen(false)
    }

    return (
        <>
            <div className="col-12">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={`/profile/` + restaurantProfile.table_qr}>
                                Profile
                            </Link>
                            <ChevronRight className="span" /> Feedback
                        </div>
                    </div>
                </div>
            </div>

            <Container className='mt-2' fixed>
                <Box component="form" onSubmit={handleSubmit} noValidate id='feedback-form'>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <TextField
                                className={`col-12`}
                                required
                                id="outlined-required"
                                label="First Name"
                                name='firstname'
                                error={formError.type == 'First Name' ? true : false}
                                helperText={formError.type == 'First Name' ? formError.error : ''}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={`col-12`}
                                required
                                id="outlined-required"
                                label="Last Name"
                                name='lastname'
                                error={formError.type == 'Last Name' ? true : false}
                                helperText={formError.type == 'Last Name' ? formError.error : ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={`col-12`}
                                required
                                id="outlined-required"
                                label="Phone"
                                defaultValue=""
                                name='phone'
                                error={formError.type == 'Phone' ? true : false}
                                helperText={formError.type == 'Phone' ? formError.error : ''}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={`col-12`}
                                required
                                id="outlined-required"
                                label="Email"
                                name='email'
                                error={formError.type == 'Email' ? true : false}
                                helperText={formError.type == 'Email' ? formError.error : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={`col-12`}
                                required
                                id="outlined-required"
                                label="Message"
                                multiline
                                rows={4}
                                name='message'
                                error={formError.type == 'Message' ? true : false}
                                helperText={formError.type == 'Message' ? formError.error : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}>
                                <Typography gutterBottom>
                                    Rate :
                                </Typography>
                                <Rating name="rate" defaultValue={2} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit"
                                variant="contained"
                                className='col-12 bg-app-color'
                                startIcon={loading && <CircularProgress size={20} sx={{ color: 'grey.500' }} />}
                                disabled={loading}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Snackbar
                open={snakbarOpen}
                autoHideDuration={1500}
                message="Feedback has been sent successfully"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{ marginBottom: 7 }}
                onClose={handleSnackbarClose}
            />
        </>
    )
}

export default Feedback