import React from 'react';
import { Outlet } from 'react-router-dom';

// components
import Header from './Header'
import Footer from './Footer'

// logics
import URLChecker from '../logics/URLChecker'
import SetResProfile from '../logics/SetResProfile'


const Layout = () => {
    return (
        <>
            <URLChecker />
            <SetResProfile />
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default Layout