import React, {useState} from 'react';
import './FoodDetails.css';

import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export var foodSize;
export var foodCurrency;
export var foodPrice;
export var foodSizeId;

export const FoodSize = (props) => {
    const {size, price, size_id, currency} = props.data;

    const handleSizeChange = (event) => {
        foodSize = event.target.value;
        foodCurrency = currency;
        foodPrice = price;
        foodSizeId = size_id;        
    }

    const displaySize = () => {
        if (size == 0) {
            foodSize = 0;
            return 'N/A';
        }
        else if (size == 1) {
            return <button className={`btn btn-outline-dark btn-sm size-btn`} price={price + ' ' + currency} value={'1'} onClick={handleSizeChange}>S</button>
        }
        else if (size == 2) {
            return <button className={`btn btn-outline-dark btn-sm size-btn`} price={price + ' ' + currency} value={'2'} onClick={handleSizeChange}>M</button>
        }
        else
            return <button className={`btn btn-outline-dark btn-sm size-btn`} price={price + ' ' + currency} value={'3'} onClick={handleSizeChange}>L</button>
    }


    return (
        <>
        {displaySize()}
        </>
    )
}

