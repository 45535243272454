import React, { useState, createContext } from 'react';

export const ResProfileContext = createContext(null);

export const ResProfileContextProvider = (props) => {

    const [restaurantProfile, setRestaurantProfile] = useState([]);

    const contextValue = { restaurantProfile, setRestaurantProfile};

    return <ResProfileContext.Provider value={contextValue}>{props.children}</ResProfileContext.Provider>
}

