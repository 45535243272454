import logo from './logo.svg';
import './App.css';
import Layout from './components/layout/Layout'
import LayoutSecondary from './components/layout-secondary/Layout'
import Home from './pages/Home'
import Cart from './pages/cart/Cart'
import Notification from './pages/notification/Notification'
import NotFound from './pages/NotFound'

import Profile from './pages/profile/Profile'
import Feedback from './pages/profile/feedback/Feedback'
import About from './pages/profile/about/About'
import Contact from './pages/profile/contact/Contact.jsx'


import Categories from './pages/categories/Categories'
import Foods from './pages/food-list/FoodList'
import FoodDetails from './pages/food-details/FoodDetails'

// Auth pages
import SignUp from './pages/auth/signup/SignUp'

import { Route, Routes } from 'react-router-dom';
import { CartContextProvider } from './context/cart/CartContext'
import { SearchContextProvider } from './context/search/SearchContext'
import { ResProfileContextProvider } from './context/restaurant/ProfileContext'
import { NotificationContextProvider } from './context/notification/NotificationContext'
function App() {
    return (
        <>
            <NotificationContextProvider>
                <ResProfileContextProvider>
                    <SearchContextProvider>
                        <CartContextProvider>
                            <Routes>
                                {/*<Layout keywords={""} description={""} title={"izi-get"}>*/}

                                {/*<Route exact path="/home/:id" Component={Home}/>*/}
                                {/*<Route path="/profile" Component={Profile}/>*/}
                                {/*<Route path="/cart" Component={Cart}/>*/}
                                {/*<Route path="/notification" Component={Notification}/>*/}
                                {/*</Layout>*/}


                                <Route element={<Layout />}>
                                    <Route exact path="/home/:id" Component={Home} />
                                    <Route path="/profile" Component={Profile} />
                                    <Route path="/cart" Component={Cart} />
                                    <Route path="/notification" Component={Notification} />
                                    <Route path="/categories" Component={Categories} />
                                    <Route path="/foods" Component={Foods} />
                                    <Route path="/foods/details" Component={FoodDetails} />
                                    <Route path="/profile/feedback" Component={Feedback} />
                                    <Route path="/profile/about" Component={About} />
                                    <Route path="/profile/contact" Component={Contact} />


                                </Route>

                                <Route element={<LayoutSecondary />}>
                                    <Route path="/signup" Component={SignUp} />
                                </Route>


                                <Route path="*" Component={NotFound} />
                            </Routes>
                        </CartContextProvider>
                    </SearchContextProvider>
                </ResProfileContextProvider>
            </NotificationContextProvider>
        </>
    );
}

export default App;
