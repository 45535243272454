import React, { useState, useEffect, useContext } from 'react'
import './Profile.css'
import { Link } from 'react-router-dom';

// import provides
import { useAuth } from '../../context/auth/AuthContext';

// components
import * as serverURL from '../../config/Constants'
import { ResProfileContext } from '../../context/restaurant/ProfileContext';

// MUI Library
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';

import { Info, Phone as PhoneIcon, Feedback as FeedbackIcon, Lock as LockIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material';

const Profile = () => {
    const { user } = useAuth();

    // import restaurant profile context
    const { restaurantProfile } = useContext(ResProfileContext);


    // logout user
    const handleLogout = async () => {
        localStorage.removeItem('iziuser');
        window.location.href = '/home/' + restaurantProfile.table_qr;
    };

    const [dailogOpen, setDailogOpen] = useState(false);
    const [custStats, setCustStats] = useState(JSON.parse(localStorage.getItem('izicuststatistics')));

    const handleDailogClose = () => {
        setDailogOpen(false);
    }

    return (
        <>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }} className={`p-2`}>
                {user ? (
                    <Link to={'/profile/user/edit'}>
                        <ListItem alignItems="flex-start">
                            <Grid container alignItems="center" justifyContent="flex-end" style={{ margin: 'auto 0' }} spacing={1}>
                                <Grid item xs={12}>
                                    <ListItemAvatar style={{ textAlign: 'center', marginBottom: '10px' }}>
                                        <Avatar alt={user.full_name} src={serverURL.CUSTOMER_IMG_URL + user.img_path} sx={{ width: 100, height: 100 }} style={{ margin: '0 auto' }} />
                                        <ListItemText
                                            primary={<Typography variant="body2" style={{ color: '#459fca', fontWeight: 'bold' }} className={'fs-6 mt-2'}>{user.full_name}</Typography>}
                                        />
                                    </ListItemAvatar>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card variant="outlined">
                                        <ListItemText style={{ textAlign: 'center' }}
                                            primary={<Typography variant="body2" style={{ fontWeight: 'bold' }} className={'fs-4'}>{custStats != '' ? custStats[0]?.total_order : '0'}</Typography>}
                                            secondary={<Typography variant="body2" style={{ fontSize: '12px' }}>{'Orders'}</Typography>}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4} >
                                    <Card variant="outlined" >
                                        <ListItemText style={{ textAlign: 'center' }}
                                            primary={<Typography variant="body2" style={{ fontWeight: 'bold' }} className={'fs-4'}>{custStats != '' ? custStats[0]?.total_price : '0'}</Typography>}
                                            secondary={<Typography variant="body2" style={{ fontSize: '12px' }}>{'Payment'}</Typography>}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card variant="outlined">
                                        <ListItemText style={{ textAlign: 'center' }}
                                            primary={<Typography variant="body2" style={{ fontWeight: 'bold' }} className={'fs-4'}>{custStats.length}</Typography>}
                                            secondary={<Typography variant="body2" style={{ fontSize: '12px' }}>{'Restaurants'}</Typography>}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </Link>

                ) : (
                    <ListItem alignItems="flex-start" className='mb-2 px-4'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ListItemText
                                    primary={<Typography variant="body2" style={{ color: '#459fca', fontWeight: 'bold' }} className={`fs-5 mt-2`}>Welcome to IZI-GET</Typography>}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Link to={"/signin/" + restaurantProfile.table_qr}>
                                    <Button variant="contained" style={{ backgroundColor: '#ccc', color: 'black', borderRadius: '20px', width: '100%' }}>Sign In</Button>
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to={"/signup/" + restaurantProfile.table_qr}>
                                    <Button variant="contained" className='bg-app-color' style={{ borderRadius: '20px', width: '100%' }} >Sign Up</Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </ListItem>
                )}
                <Divider sx={{ marginTop: 1 }}></Divider>
                <nav aria-label="main mailbox folders" className='px-2 '>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <Link to={"/profile/contact/" + restaurantProfile.table_qr}>
                                    <ListItemText primary="Contact us" />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Info />
                                </ListItemIcon>
                                <Link to={"/profile/about/" + restaurantProfile.table_qr}>
                                    <ListItemText primary="About us" />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FeedbackIcon />
                                </ListItemIcon>
                                <Link to={"/profile/feedback/" + restaurantProfile.table_qr}>
                                    <ListItemText primary="Feedback" />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LockIcon />
                                </ListItemIcon>
                                <Link to={"/profile/privacy/" + restaurantProfile.table_qr}>
                                    <ListItemText primary="Privacy & policy" />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        {user && (
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Sign out" onClick={() => setDailogOpen(true)} />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </nav>
            </Box>
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to sign out?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>No</Button>
                    <Button onClick={handleLogout} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm sign out dialog */}
        </>
    )
}

export default Profile