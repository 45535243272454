import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import { useAuth } from '../../context/auth/AuthContext';
const Header = () => {

    const navigate = useNavigate();

    // import profile context
    const { restaurantProfile, setRestaurantProfile } = useContext(ResProfileContext);

    const { user } = useAuth();

    const { id } = useParams();

    useEffect(function () {
        if (user) {
            navigate('/home/' + id);
        }
    }, [])

    return (
        <>

        </>
    )
}

export default Header