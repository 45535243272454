import React, { useState, createContext } from 'react';

export const ValidationContext = createContext(null);

export const ValidationContextProvider = (props) => {

    const [formError, setFormError] = React.useState({});

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const validate = (type, name, value, ConfValue = '') => {
        switch (type) {
            case 'input':
                if (value.length == 0) {
                    const error = { type: name, error: `${name} can not be empty` };
                    setFormError(error)
                    return -1
                }
                break;
            case 'email':
                if (value.length == 0) {
                    const error = { type: name, error: `${name} Address can not be empty` };
                    setFormError(error)
                    return -1
                } else if (!isValidEmail(value)) {
                    const error = { type: name, error: `Email is invalid` };
                    setFormError(error)
                    return -1
                }
                break;
            case 'password':
                if (value.length < 8) {
                    const error = { type: name, error: `${name}  must be 8 characters long!` };
                    setFormError(error)
                    return -1
                }
                break;
            case 'confirmPassword':
                if (value.length < 8) {
                    const error = { type: name, error: `${name}  must be 8 characters long!` };
                    setFormError(error)
                    return -1
                }
                if (value !== ConfValue) {
                    const error = { type: name, error: `Passwords do not match. Please try again.`};
                    setFormError(error)
                    return -1
                }
                break;
            default:
                return true;
                break;
        }
    }

    const setFormErrorEmpty = () => {
        setFormError({})
    }
    const contextValue = { formError, validate, setFormErrorEmpty , setFormError};

    return <ValidationContext.Provider value={contextValue}>{props.children}</ValidationContext.Provider>
}

