import React from 'react'
import { Link } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';
import * as serverURL from '../../config/Constants'

const CustomCarousel = (props) => {

    return (
        <Carousel indicators={false}>
            {
                props.specialOffer.map((c, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <Link to="/foods/details" state={{ foodDetails: c }}>
                                <img src={serverURL.CAROUSEL_IMG_URL + c.carousel_img} className="d-block w-100" alt="..." style={{ borderRadius: '10px' }} />
                            </Link>
                        </Carousel.Item>
                    )
                })
            }
            {
                props.carousels.map((c, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img src={serverURL.CAROUSEL_IMG_URL + c.img} className="d-block w-100" alt="..." style={{ borderRadius: '10px' }} />
                        </Carousel.Item>
                    )
                })
            }
        </Carousel >
    )
}

export default CustomCarousel;