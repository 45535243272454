import React, { useState, useContext } from 'react';
import * as serverURL from '../../config/Constants'
import { Plus, FileMinus, Heart } from "react-bootstrap-icons";
import './Food.css';


const Food = (props) => {

    const { iid, id, name, img, description, size, tags, total_orders } = props.data;

    const displaySize = (size) => {
        if (size == 0) {
            return 'N/A';
        }
        else if (size == 1) {
            return 'Small'
        }
        else if (size == 2) {
            return 'Medium'
        }
        else
            return 'Large'
    }
    return (
        <>
            <div className="food-card food-card--vertical" style={{marginBottom : '10px'}}>
                <div className="food-card_img col-4">
                    <img src={serverURL.FOOD_IMG_URL + img} alt="" />
                </div>

                <div className="food-card_content col-8">
                    <div className="food-card_title-section">
                        <div className="h6">
                            {name}
                            <span className="badge-box">
                                {'filterBy' in props ?
                                    <span className="badge bg-app-color float-end" style={{'marginRight' : '-10px'}}>
                                        {displaySize(size[0].size)}
                                    </span>
                                    :
                                    tags.map((tag, index) => {
                                        return <span key={index}
                                            className="badge rounded-pill bg-success">{tag}</span>
                                    })
                                }
                            </span>
                        </div>
                        <div className="small">{description.substring(0,30)}</div>
                    </div>

                    <div className="food-card_bottom-section col-12">
                        <div className="row">
                        <div className="food-card_price col-6">
                                <span>
                                    { size[0].currency + size[0].price }
                                </span>
                            </div>
                            <div className="food-card_order-count col-6 text-end pe-2">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <button className="btn btn-outline-secondary minus-btn"
                                            type="button"
                                            onClick={(e) => props.remove(props.data, e)}
                                        >
                                           &#8722; 
                                        </button>
                                    </div>
                                    <label type="text" className="form-control input-manulator text-center input"
                                        placeholder=""
                                        aria-describedby="button-addon1">
                                        {props.cartItem?.filter((row) => row.id == id).length > 0  ? 'filterBy' in props ? props.getTotalOrdersForItem(props.data, true) : props.getTotalOrdersForItem(id) : '0'}
                                    </label>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary add-btn"
                                            type="button"
                                            onClick={(e) => props.addInCart(props.data, e)}
                                        ><Plus />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Food
