import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


// import providers
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import { useAuth } from '../../context/auth/AuthContext';

// import APIs address
import * as serverURL from '../../config/Constants'

export default function SetResProfile() {
    const { user } = useAuth();

    const { id } = useParams();

    // in here check is user is not logged in then create a userId with 90 min expiration
    let userId = '';
    // check if user is not logged in
    if (!user) {
        var unRegUser = localStorage.getItem('iziuserId');
        if (unRegUser) {
            // get the userId from local storage
            const iziuser = localStorage.getItem('iziuserId');
            userId = iziuser;

        } else {
            // create a userId and store it to local storage
            const randomNum = uuidv4().replace(/-/g, '').substring(0, 30);
            localStorage.setItem('iziuserId', randomNum);
            userId = randomNum;
        }
    } else {
        userId = user.email;
    }
    // end

    // import restaurant profile context
    const { setRestaurantProfile } = useContext(ResProfileContext);

    // get the restaurant profile from api
    useEffect(() => {
        console.log(id)
        axios.post(serverURL.SERVER_URL + 'profile-by-qr/' + id, { userId: userId }, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then((result) => {
            const data = result.data;
            setRestaurantProfile(data.profile)
            localStorage.setItem('izicuststatistics', JSON.stringify(data.customer_statistics));
        })
    }, [])

    return null
}
