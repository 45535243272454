import React, { useContext } from 'react';
import './Contact.css';

import { Link } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";


import { ResProfileContext } from '../../../context/restaurant/ProfileContext';

// MUI Library
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { PlaceOutlined as LocationIcon,  LocalPhoneOutlined, Language as WorldIcon, EmailOutlined as EvelopeIcon } from '@mui/icons-material';

const Contact = () => {

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    return (
        <>
            <div className="col-12">
                <div className="page-header breadcrumb-wrap">
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to={`/profile/` + restaurantProfile.table_qr}>
                                Profile
                            </Link>
                            <ChevronRight className="span" /> Contact us
                        </div>
                    </div>
                </div>
            </div>
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} className={`p-2`}>
                <nav aria-label="main mailbox folders" className={`p-2`}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LocationIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="body2">8 Leeds Street, Te Aro Wellington</Typography>}  />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LocalPhoneOutlined />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="body2">+64 22 499 7941</Typography>} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <EvelopeIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="body2">info@izi-get.com</Typography>} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <WorldIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="body2">www.izi get.com</Typography>} />
                            </ListItemButton>
                        </ListItem>
                       
                    </List>
                </nav>
            </Box>


        </>
    )
}

export default Contact