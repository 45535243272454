import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

//import Icons
import tableIcon from '../../assets/img/png/table.png';
import searchIcon from '../../assets/img/svgs/search.svg';
import boxArrowRight from '../../assets/img/svgs/box-arrow-in-right.svg';
import { Person, Cart, List, Bell, HouseDoor, X, XLg } from "react-bootstrap-icons";
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';

// providers
import './Header.css';
import * as serverURL from '../../config/Constants'
import { SearchContext } from '../../context/search/SearchContext';
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import { NotificationContext } from '../../context/notification/NotificationContext';
import { CartContext } from '../../context/cart/CartContext';

// import custom hook of authentication
import { useAuth } from '../../context/auth/AuthContext';

const Header = () => {

    // useEffect(() => {
    // 	// Prompt confirmation when reload page is triggered
    // 	window.onbeforeunload = () => { return "" };

    // 	// Unmount the window.onbeforeunload event
    // 	return () => { window.onbeforeunload = null };
    // }, []);

    // import restaurant profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    // get the route name
    const location = useLocation();
    const currentRoute = location.pathname.split('/')[1];
    // end of route name

    const { user, setUser } = useAuth();
    const [showSearch, setShowSearch] = useState(false);
    const [active, setActive] = useState('notification');
    const [linkActive, setLinkActive] = useState('notification');


    // activeate the page icon to blue
    useEffect(() => {
        if (currentRoute == 'home') {
            setActive('')
            setLinkActive('')
        } else if (currentRoute == 'notification') {
            setActive('notification')
            setLinkActive('notification')
        } else if (currentRoute == 'cart') {
            setActive('cart')
            setLinkActive('cart')
        } else if (currentRoute == 'profile') {
            setActive('profile')
            setLinkActive('profile')
        }
    }, [])
    // end

    // import cartContext params
    const { getAllTotalOrders } = useContext(CartContext);

    // import notification context/providers params
    const { notification } = useContext(NotificationContext);

    // import search function for search fragment
    const { toggle, setSearchedFood } = useContext(SearchContext);

    function handleSearchClick() {
        setShowSearch(!showSearch);
        toggle();
        if (!showSearch) {
            // document.getElementById("search-input").focus();
            // $('#search-input').focus();
        }
    }
    function hideSearchBox() {
        setShowSearch(false)
        toggle();
        setSearchedFood([])
    }

    const searchFood = (e) => {
        axios.post(serverURL.SERVER_URL + 'search/' + e.target.value, { tbl_id: restaurantProfile.table_id }, {
            headers: {
                'Content-Type': 'application/json',
                'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
        }).then((result) => {
            const data = result.data;
            setSearchedFood(data.foods);
        })
    }

    return (
        <>
            <nav className="navbar align-items-center border-bottom">
                <div className="container-fluid">
                    <div className="d-flex align-items-center ">
                        <a className="navbar-brand m-0 textColor fw-bold brand">IZI-GET</a>
                        <div id="verticalbar" className="mx-xxl-2 mx-1 d-none d-lg-inline-block"></div>
                        <a className="nav-link textColor fw-lighter lh-sm brandSubHeading d-none d-lg-inline-block">Welcome
                            <br />
                            {/*<span className="fw-medium brandSubHeading d-none d-lg-inline-block"> to Noha!</span>*/}
                        </a>
                        <div className="position-relative ms-4 d-none d-xl-inline-block">
                            <input className="searchInput bg-body-tertiary iconCursor ps-md-5 p-1 p-md-2 " type="search"
                                placeholder="Search Your food" aria-label="Search" />
                            <img className="position-absolute searchImg iconCursor " src={searchIcon}
                                alt="search" />
                        </div>

                    </div>
                    <div className="d-flex align-items-center">
                        <div className="d-none d-lg-block">
                            <ul className="d-flex m-0 list-unstyled ">
                                <li className="nav-item iconChange  me-4 pt-2">
                                    <Link to={"/home/" + restaurantProfile.table_qr}
                                        onClick={() => setLinkActive('')}
                                        className={`nav-link text-center  p-0`}>
                                        <div
                                            className={`${linkActive == '' && " pageActive-lg"} icon-div d-flex align-items-center overflow-hidden changeWidth mx-auto`}>
                                            <HouseDoor size={25} />
                                        </div>
                                        <div className="smallFont textColor text-center">Home</div>
                                    </Link>
                                </li>
                                <li className="nav-item iconChange   me-4 pt-2">
                                    <Link to={"/notification/" + restaurantProfile.table_qr}
                                        onClick={() => setLinkActive('notification')}
                                        className="nav-link text-center p-0 ">
                                        <div
                                            className={`${linkActive == 'notification' && " pageActive-lg"} icon-div d-flex align-items-center overflow-hidden changeWidth mx-auto`}>
                                            <FastfoodOutlinedIcon size={25} />
                                        </div>
                                        <div className="smallFont textColor">Order List</div>
                                    </Link>
                                </li>
                                <li className="nav-item iconChange  me-4 pt-2">
                                    <Link to={"/cart/" + restaurantProfile.table_qr}
                                        onClick={() => setLinkActive('cart')}
                                        className="nav-link text-center p-0">
                                        <div
                                            className={`${linkActive == 'cart' && " pageActive-lg"} icon-div d-flex align-items-center overflow-hidden changeWidth mx-auto`}>
                                            <Cart size={25} />
                                        </div>
                                        <div className="smallFont textColor">Cart</div>
                                    </Link>
                                </li>
                                <li className="nav-item iconChange  me-4 pt-2">
                                    <Link to={"/profile/" + restaurantProfile.table_qr}
                                        onClick={() => setLinkActive('profile')}
                                        className="nav-link text-center p-0">
                                        <div
                                            className={`${linkActive == 'profile' && " pageActive-lg"} icon-div d-flex align-items-center overflow-hidden changeWidth mx-auto`}>
                                            <Person size={25} />
                                        </div>
                                        <div className="smallFont textColor">Profile</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div id="iconContainer"
                            className="d-flex justify-content-center align-items-center rounded-5 bg-light d-xl-none">
                            {currentRoute === 'home' && (
                                <>
                                    {!showSearch ? (
                                        <img src={searchIcon} alt="search" className="search-icon" onClick={handleSearchClick} />
                                    ) : (
                                        <span className="search-icon" onClick={() => hideSearchBox()} ><XLg /></span>
                                    )}
                                    {showSearch && (
                                        <div className="search-field search-field-visible">
                                            <input
                                                id="search-input"
                                                type="text"
                                                placeholder="Search..."
                                                onChange={searchFood}
                                                autoFocus
                                            />
                                        </div>
                                    )}
                                </>
                            )}

                        </div>
                        {!user && currentRoute !== 'profile' && (
                            <Link to={"/signin/" + restaurantProfile.table_qr}
                                className="btn btn-sm m-sm-2 m-1 bg-app-color text-white rounded-5 px-sm-3 px-2">
                                Sign In
                                <img className="d-none d-lg-inline-block" src={boxArrowRight}
                                    alt="box-arrow" />
                            </Link>
                        )}

                        <div className="table-area">
                            <div className="table-no">{restaurantProfile.table_no}</div>
                            <img className="ms-2" src={tableIcon} alt="grid-3x3" />
                        </div>
                    </div>
                </div>
            </nav>
            <nav id="secondNav" className="bottom-0 position-fixed end-0 start-0">
                <div className="d-lg-none">
                    <div className="container-fluid d-flex justify-content-between shadow p-4 pt-2 pb-2">
                        <Link to={`/home/` + restaurantProfile.table_qr}
                            onClick={() => setActive('')}
                            className={`${active == '' && "pageActive"} text-decoration-none textColor text-center tab`}>
                            <HouseDoor size={20} />
                        </Link>
                        <Link to={"/notification/" + restaurantProfile.table_qr}
                            onClick={() => setActive('notification')}
                            className={`${active == 'notification' && "pageActive"} text-decoration-none textColor text-center tab`}>
                            <FastfoodOutlinedIcon size={20} />
                            {currentRoute !== 'notification' && (
                                <>
                                    {notification > 0 && (
                                        <span className="badge badge-custome bg-app-color  badge-number">{notification}</span>
                                    )}
                                </>
                            )}
                        </Link>
                        <Link to={"/cart/" + restaurantProfile.table_qr}
                            onClick={() => setActive('cart')}
                            className={`${active == 'cart' && "pageActive"} text-decoration-none textColor  text-center tab`}>
                            <Cart size={20} />
                            {currentRoute !== 'cart' && (
                                <>
                                    {getAllTotalOrders() > 0 && (
                                        <span className="badge badge-custome bg-app-color  badge-number">{getAllTotalOrders()}</span>
                                    )}
                                </>
                            )}

                        </Link>
                        <Link to={"/profile/" + restaurantProfile.table_qr}
                            onClick={() => setActive('profile')}
                            className={`${active == 'profile' && "pageActive"} text-decoration-none textColor  text-center tab`}>
                            <Person size={20} />
                        </Link>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header