import React from 'react'

const NotFound = () => {
    return (
        <>
        <div style={{textAlign: 'center', position: 'absolute', top : '40%'}}>
            <h3>This table is currently in use. Please ask the restaurant to clear previous orders.</h3>
            <h3>And rescan the QR Code</h3>
        </div>
        </>
    )
}

export default NotFound