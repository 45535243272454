import React, { useState, useContext } from 'react';
import './Privacy.css';

import { Link } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";

import * as serverURL from '../../../config/Constants'
import { ResProfileContext } from '../../../context/restaurant/ProfileContext';

const Privacy = () => {

  // import profile context
  const { restaurantProfile } = useContext(ResProfileContext);

  return (
    <>
      <div className="col-12">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to={`/profile/` + restaurantProfile.table_qr}>
                Profile
              </Link>
              <ChevronRight className="span" /> Privacy
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Privacy