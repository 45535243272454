import { createContext } from 'react';
import { useCart } from '../../hooks/useCart';

export const CartContext = createContext({
    cartItem: null,
    addToCart: ()=> {},
    removeFromCart: ()=> {},
});

export const CartContextProvider = (props) => {

    const [cartItem, addToCart, removeFromCart, getTotalOrdersForItem, setCartEmpty, getAllTotalOrders, getTotalPrice] = useCart();
    
    const contextValue = { cartItem, addToCart, removeFromCart, getTotalOrdersForItem , setCartEmpty, getAllTotalOrders, getTotalPrice};
   
    return (
        <CartContext.Provider value={contextValue}>
            {props.children}
        </CartContext.Provider>
    )
}