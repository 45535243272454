import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// improt izi logo
import izi from '../../../assets/logo/izi.png'

// MUI library
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';


// providers
import { useAuth } from '../../../context/auth/AuthContext';
import { ResProfileContext } from '../../../context/restaurant/ProfileContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';

// all server APIs url
import * as serverURL from '../../../config/Constants'

export default function SignUp() {
  const { user, setUser } = useAuth();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // import profile context
  const { restaurantProfile } = useContext(ResProfileContext);

  // import validtaion context params
  const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);

  // if user is  logged in, redirect to home page
  if (user) {
    navigate('/home/' + restaurantProfile.table_qr)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrorEmpty()
    const data = new FormData(event.currentTarget);
    const body = {
      full_name: data.get('fullname'),
      email: data.get('email'),
      password: data.get('password'),
      confirmPassword: data.get('confirm-password'),
    };
    if (validate('input', 'FullName', body.full_name) == -1) {
      return
    }
    if (validate('email', 'Email', body.email) == -1) {
      return
    }
    if (validate('password', 'Password', body.password) == -1) {
      return
    }
    if (validate('confirmPassword', 'Confirm Password', body.confirmPassword, body.password) == -1) {
      console.log(formError)
      return
    }
    setLoading(true)
    try {
      axios.post(serverURL.SERVER_URL + 'register', body, {
        headers: {
          'Content-Type': 'application/json',
          'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
        }
      }).then((result) => {
        const data = result.data;
        if (result.status === 200) {
          setUser(data.user);
          // send verification email
          axios.post(serverURL.SERVER_URL + 'send-verify', { email: body.email }, {
            headers: {
              'Content-Type': 'application/json',
              'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
            }
          }).then((result) => {
            setLoading(false)
            navigate('/email/verify', { state: body })
          })

        }
      }).catch(function (error) {
        // setFormError(error.response.data.message.message) //Please Authenticate or whatever returned from server
        if (error.response.status == 401) {
          setError(error.response.data.message)
        }
        if (error.response.status == 409) {
          setFormError({ type: "Email", error: `This email address is already in use.` })
        }
        setLoading(false)
      })
    } catch (error) {
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={izi} alt="" />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          {error.length > 0 && (
            <div className="text-danger text-center pt-1 pb-0">
              {error}
            </div>
          )}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="fullname"
                  required
                  fullWidth
                  id="fullname"
                  label="Full Name"
                  autoFocus
                  error={formError.type == 'FullName' ? true : false}
                  helperText={formError.type == 'FullName' ? formError.error : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={formError.type == 'Email' ? true : false}
                  helperText={formError.type == 'Email' ? formError.error : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={formError.type == 'Password' ? true : false}
                  helperText={formError.type == 'Password' ? formError.error : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={formError.type == 'Confirm Password' ? true : false}
                  helperText={formError.type == 'Confirm Password' ? formError.error : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="remember me"
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className={'bg-app-color'}
              startIcon={loading && <CircularProgress size={20} sx={{ color: 'grey.500' }} />}
              disabled={loading}
            >
              Sign Up
            </Button>

            <Grid container justifyContent="center">
              <Grid item>
                <Link to={"/signin/" + restaurantProfile.table_qr} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 3, mb: 1 }} >
        {'Copyright © '}
        <Link color="inherit" href="https://spottech.co.nz/">
          SpotTech
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </>
  );
}